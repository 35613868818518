.video-card-image {
  border-radius: 2px;
  box-shadow: 0 0 11px #ececec;
  overflow: hidden;
  position: relative;
  height: 200px;
}
.forCardListImg img {
  height: 75px !important;
  object-fit: cover !important;
}
.time {
  background: #dbdbdb none repeat scroll 0 0;
  border-radius: 2px;
  bottom: 5px;
  color: #333333;
  font-size: 11px;
  font-weight: 500;
  opacity: 0.8;
  padding: 0 6px;
  position: absolute;
  right: 5px;
}
.iconOfType {
  background: #dbdbdb none repeat scroll 0 0;
  border-radius: 2px;
  bottom: 5px;
  color: #333333;
  font-size: 11px;
  font-weight: 500;
  opacity: 0.8;
  padding: 0 6px;
  /* position: absolute;
  left: 5px; */
}
.iconOfTypee {
  background: #dbdbdb none repeat scroll 0 0;
  border-radius: 2px;
  bottom: 5px;
  color: #333333;
  font-size: 11px;
  font-weight: 500;
  opacity: 0.8;
  padding: 0 6px;
  position: absolute;
  left: 5px;
}
.iconDelete {
  background: #dbdbdb none repeat scroll 0 0;
  border-radius: 2px;
  top: 10px;
  color: #c22c2c;
  font-size: 18px;
  font-weight: 500;
  opacity: 0.8;
  padding: 0 6px;
  position: absolute;
  right: 5px;
  cursor: pointer;
}
.video-card:hover .time {
  opacity: unset;
}
.video-card-image img {
  width: 100%;
}
.play-icon {
  opacity: 0;
  position: absolute;
}
.play-icon .fas,
.play-icon svg {
  bottom: 0;
  height: 40px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
}
.video-card:hover .play-icon {
  background: #000 none repeat scroll 0 0;
  border-radius: 2px;
  bottom: 0;
  color: #fff;
  font-size: 35px;
  left: 0;
  opacity: 0.4;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  transition-duration: 0.4s;
}
.video-title a {
  color: #000 !important;
  font-weight: 600;
  text-decoration: none;
}
.video-title {
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.video-page {
  font-size: 11px;
}
.video-view {
  color: #acacac;
  font-size: 10px;
  font-weight: 500;
  margin: 2px 0 0;
  display: flex;
  justify-content: space-between;
}
.video-card {
  background: #fff none repeat scroll 0 0;
  border-radius: 1px;
  box-shadow: 0 0 11px #ececec;
  transition-duration: 0.4s;
}
.video-card-body {
  padding: 8px;
}

.flagContainer {
  img {
    width: 25px;
    height: 18px;
    object-fit: cover !important;
    border: 1px solid #e7e7e7;
  }
}
