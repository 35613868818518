.chatUserList {
  .userSearchBarField {
    width: 100%;
  }
}

.chatUserSearch {
  width: 200px !important;
}

.tmw_messageContainer {
  background-color: white;
}
