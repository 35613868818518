.chatContainer {
  max-width: 100% !important;
  margin: 1rem 0;

  .chatUserList {
    height: 550px;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 5px;
  }
  .chatUserInbox {
    height: 450px;
    overflow-x: hidden;
    padding: 0 1rem;

    .card {
      border-radius: 15px;
    }

    .firstUser, .secondUser {
      .card {
        width: 80%;
      }
    }

    .secondUser {
      justify-content: right;
    }    

    .MessageRequestContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 4rem;
    }
  }

  .userItem {
    cursor: pointer;
  }
  
  .activeUser {
    background-color: #eee 
  }

  .msg_nav_icon {
    display: flex;
    cursor: pointer;
    align-items: center;

    svg {
      font-size: 1.5rem;
      color: #d4d5d5;
    }
  }
}

@media (max-width: 700px) {
    .chatContainer {
      padding: 0 10px;
    }
    .chatUserInbox {
      padding: 0 !important;
    }
    .firstUser, .secondUser {
      .card {
        width: 70% !important;
      }
    }
 }
