.ContentValidationContainer {
  display: flex;
  justify-content: center;
  background: #f2f2f2;
}

.single-channel-page {
  padding: 0px 0px 0px 225px;
}

.single-channel-page .container-fluid {
  padding: 30px 30px 30px !important;
}

.icon {
  padding-right: 20px;
}

.nav-item {
  cursor: pointer;
}

.sidebar-toggled .single-channel-page {
  padding: 0px 0px 0px 90px;
}

/* Single Channel */
.single-channel-image {
  position: relative;
}
.single-channel-nav .navbar {
  padding: 0;
}
.single-channel-nav {
  background: #fff none repeat scroll 0 0 !important;
  box-shadow: 0 0 11px #ececec;
  padding: 0rem 30px 0;
}
.channel-brand {
  color: #000 important !important;
  font-size: 16px !important;
  font-weight: bold;
}
.single-channel-nav .nav-link {
  border-bottom: 2px solid transparent;
  margin: 0 12px;
  padding: 14px 0 !important;
}
.single-channel-nav .nav-item.active .nav-link,
.single-channel-nav .nav-item .nav-link:hover,
.single-channel-nav .nav-item.active .nav-link:active {
  border-color: #ff516b !important;
  color: #ff516b !important;
}
.single-channel-nav .navbar-nav {
  margin: 0 0 0 40px;
}
.channel-profile {
  bottom: 0;
  left: 0;
  padding: 1rem 30px;
  position: absolute;
  right: 0;
}
.single-channel-image .img-fluid {
  width: 100%;
  object-fit: cover;
}
.channel-profile-img {
  background: #fff none repeat scroll 0 0;
  border: 6px solid #fff;
  border-radius: 50px;
  height: 90px;
  width: 90px;
}
.social {
  color: #fff;
  float: right;
  margin: 62px 0 0;
}
.social a {
  background: #000 none repeat scroll 0 0;
  border-radius: 2px;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  padding: 5px 10px;
}

.talent-wrapper-modal {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // margin: 200px 0 0 0;
  padding: 0px !important;
  .react-responsive-modal-closeButton {
    display: none !important;
  }
  .talentheadings-wrappers {
    position: relative;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
    background: linear-gradient(135deg, #ff516b 0%, #826cfd 100%) !important;

    .remove-btns {
      background: none;
      border: none;
      color: #fff;
      outline: none;
      width: 50px;
      i {
        font-size: 18px;
      }
    }
    .talentheading {
      margin: 0;
      padding: 10px;
      color: #fff;
    }
  }

  .talent-popwrap {
    padding: 10px 30px;
    text-transform: capitalize;
    .css-1s2u09g-control {
      border: 1px solid #eceff0;
      border-radius: 23px;
    }
    .btnList {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      Button {
        margin-top: 0;
        border-radius: 10px !important;
        padding: 3px 30px !important;
        border: none !important;
        box-shadow: none !important;
        background: #5e5e5e;
      }
    }
    .css-1pahdxg-control {
      border: 1px solid #eceff0;
      box-shadow: none;
      border-radius: 23px;
    }
    .select-talent-wrapp {
      width: 100%;
      height: 50px;
      border-radius: 5px;
      padding: 10px;
      outline: none;
      border: 1px solid #eceff0;
      background-color: #f9f9f9;
      margin-bottom: 20px;
    }
    .savebtnwrappp {
      margin-top: 10px;
      text-align: right;
      .talentsevebtn {
        background-color: #0baf4d;
        color: #fff;
        height: 50px;
        border-radius: 5px;
        outline: none;
        font-size: 18px;
        font-weight: bold;
        border: none;
        width: 100%;
        word-break: keep-all;
      }
    }

    .ajexsBox-wrapper {
      width: 100%;
      left: 0;
      top: 52px;
      position: absolute;
      padding: 0;
      z-index: 30;
      max-height: 250px;
      overflow-y: scroll;
      scroll-behavior: smooth;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      .ajesitems {
        display: flex;
        align-items: center;
        background-color: #fff;
        border: 1px solid #eceff0;
        list-style: none;
        padding: 10px 12px;
        cursor: pointer;
        font-size: 13px;
        &:hover {
          background-color: #eceff0;
        }
      }
    }
  }
}

@media (max-width: 575.98px) {
  .single-channel-page {
    padding: 0 0 0 90px !important;
  }
  .sidebar-toggled .single-channel-page {
    padding: 0 !important;
  }
  .single-channel-page .container-fluid {
    padding: 30px 15px 15px !important;
  }
  .single-channel-nav {
    padding: 15px;
  }
  .channel-profile {
    padding: 10px;
    position: relative;
    text-align: center;
  }
  .social {
    display: none;
  }
  .navbar-toggler {
    padding: 1px 3px;
  }
  .single-channel-nav .navbar-nav {
    margin: 0;
  }
  .single-channel-nav .nav-link {
    cursor: pointer;
    margin: 0;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .single-channel-page {
    padding: 0 0 0 90px !important;
  }
  .sidebar-toggled .single-channel-page {
    padding: 0 !important;
  }
  .single-channel-page .container-fluid {
    padding: 30px 15px 15px !important;
  }
  .single-channel-nav {
    padding: 15px;
  }
  .channel-profile {
    padding: 10px;
    position: relative;
    text-align: center;
  }
  .social {
    display: none;
  }
  .navbar-toggler {
    padding: 1px 3px;
  }
  .single-channel-nav .navbar-nav {
    margin: 0;
  }
  .single-channel-nav .nav-link {
    margin: 0;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .single-channel-page {
    padding: 0 0 0 225px !important;
  }
  .sidebar-toggled .single-channel-page {
    padding: 0 0 0 90px !important;
  }
  .single-channel-page .container-fluid {
    padding: 30px 15px 15px !important;
  }
  .single-channel-nav {
    padding: 15px;
  }
  .channel-profile {
    padding: 15px;
  }
  .social {
    display: none;
  }
  .navbar-toggler {
    padding: 1px 3px;
  }
  .single-channel-nav .navbar-nav {
    margin: 0;
  }
  .single-channel-nav .nav-link {
    margin: 0;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .single-channel-page {
    padding: 0 0 0 225px !important;
  }
  .sidebar-toggled .single-channel-page {
    padding: 0 0 0 90px !important;
  }
  .single-channel-page .container-fluid {
    padding: 30px 15px 15px !important;
  }
  .single-channel-nav {
    padding: 0 15px;
  }
  .channel-profile {
    padding: 15px;
  }
  .social {
    display: none;
  }
  .navbar-toggler {
    padding: 1px 3px;
  }
  .single-channel-nav .navbar-nav {
    margin: 0 0 0 20px;
  }
  .single-channel-nav .nav-link {
    margin: 0 7px;
  }
  .single-channel-nav .form-control,
  .single-channel-nav .btn-outline-success {
    display: none;
  }
}

.talent-wrapper-modal {
      max-width: 900px;
}

.talent-popwrap .input-wrapper {
  height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.talent-popwrap .input-wrapper::-webkit-scrollbar {
  width: 0.5em;
}

.talent-popwrap .input-wrapper::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.talent-popwrap .input-wrapper::-webkit-scrollbar-thumb {
  background: linear-gradient(135deg, #ff516b 0%, #826cfd 100%);
  outline: 1px solid slategrey;
}

.fb-background {
  background: #2663b6 !important;
}

.insta-background {
  background: #ff5661 !important;
}

.tw-background {
  background: #2ca9ea !important;
}

.talentTags {
  justify-content: space-between;
  margin: 0 5px 5px 0;
  background: none !important;
  display: flex !important;
  min-width: 150px;
  max-width: 200px;
  align-items: center;
  border: 1px solid #cfcfcf !important;

  svg {
    margin-left: 5px;
  }
}

.profileIntro {
  width: 90%;
}


@media (max-width: 786px) {
  .profileIntro {
    width: 100%;
    margin-bottom: 1rem;
  }
}
