.category-item img {
	background: #fff none repeat scroll 0 0;
	// border-radius: 50px;
	// box-shadow: 0 0 2px #ccc;
	height: 60px;
	margin: 1px 0 8px;
	object-fit: scale-down;
	transform: scale(1);
	width: 60px;
}
.category-item:hover img {
	transform: scale(1.09);
	transition-duration: 0.4s;
}
.category-item {
	background-color: white;
	box-shadow: 3px 3px 6px #cbcbcb;
	margin: 6px 2px;
	padding: 24px 12px;
	text-align: center;
	border-radius: 8px;
}
.top-category .category-item {
	padding: 20px 11px;
}
.category-item h6 {
	font-size: 13px;
	margin: 5px 0 3px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	color:black;
}
.category-item p {
	font-size: 11px;
	font-weight: 500;
	margin: 0;
}

.categoryIcon-Sports {
  color: #4abd3e;
}
.categoryIcon-Music {
  color: #ffb310;
}
.categoryIcon-VisualArts {
  color: #fa6137;
}
.categoryIcon-PerformingArts {
  color: #3b67ec;
}
