.postTypeSlideItem {
  background-color: white;
  box-shadow: 3px 3px 6px #cbcbcb;
  width: 95% !important;
  text-align: center;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;

  p {
    margin-bottom: 0;
  }
}

.activeType {
  background: #f0f0f0;
  font-weight: bold;
}
