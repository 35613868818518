.selected {
  background: linear-gradient(135deg, #ff516b 0%, #826cfd 100%) !important;
}

.top-mobile-search {
  display: none;
}
.top-mobile-search {
  margin: -16px -15px 19px -18px;
}
.top-mobile-search input {
  border-radius: 0;
  box-shadow: none !important;
}
.top-mobile-search .btn {
  border-radius: 0;
  font-size: inherit;
}

.filterContainer {
  background-color: #fff;
  padding: 10px 20px;
  margin-bottom: 1rem;

  .filterHeader {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.5px solid rgb(146, 146, 146);
    margin-bottom: 1.5rem;

    p {
      margin-bottom: 0;
    }
  }
  .FilterOptionContainer {
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 0.5px solid rgb(222, 222, 222);

    .filterOptionHeader {
      display: flex;
      justify-content: space-between;
    }
  }
}


@media (max-width: 575.98px) {
  .top-mobile-search {
    display: block;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .top-mobile-search {
    display: block;
  }
}
