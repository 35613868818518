.AllSubcategoryItems {
  margin-left: 9%;
  padding: 10px;
}

.userNameVideoCard a {
  color: #44b100 !important;
}

.userNameVideoCard a:hover {
  color: #358900 !important;
}

body::-webkit-scrollbar {
  width: 0.5em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background: linear-gradient(
    135deg,
    #4abd3e 10%,
    #ffb310 28%,
    #fa6137 36%,
    #3b67ec 90%
  );
  outline: 1px solid slategrey;
}

/* left container */

.leftContainer::-webkit-scrollbar {
  width: 0.5em;
}

.leftContainer::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.leftContainer::-webkit-scrollbar-thumb {
  background: linear-gradient(
    135deg,
    #4abd3e 10%,
    #ffb310 28%,
    #fa6137 36%,
    #3b67ec 90%
  );
  outline: 1px solid slategrey;
}

.notificationContainer::-webkit-scrollbar {
  width: 0.2em;
}

/* .notificationContainer::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px slategrey;
} */

.notificationContainer::-webkit-scrollbar-thumb {
  background: rgb(127, 139, 152);
  outline: 1px solid slategrey;
}

.chatUserList::-webkit-scrollbar {
  width: 0.2em;
}

.chatUserList::-webkit-scrollbar-thumb {
  background: rgb(127, 139, 152);
  outline: 1px solid slategrey;
}

.chatUserInbox::-webkit-scrollbar {
  width: 0.4em;
}

.chatUserInbox::-webkit-scrollbar-thumb {
  background: rgb(127, 139, 152);
  outline: 1px solid slategrey;
}

.contentCard {
  padding: 2px;
}

._loading_overlay_overlay {
  z-index: 2000;
}

._loading_overlay_content {
  position: fixed;
  left: 0;
  right: 0;
  top: 30%;
}

.previewScreen {
  .slick-slide
  { 
    background: black;
  }
}

@media (max-width: 786px) {
  #wrapper #content-wrapper {
    padding-top: 4rem;
  }
  ._loading_overlay_wrapper {
    position: initial !important;
  }
  .mobileFilter button {
    width: 100%;
  }
}

@media (min-width: 787px) {
  .slick-slide img {
    max-height: 450px !important;
  }
}
