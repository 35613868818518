.selected {
  background: linear-gradient(135deg, #ff516b 0%, #826cfd 100%) !important;
}

.paceLoader > div > div {
  height: 5px !important;
  background: linear-gradient(135deg, #826cfd 0%, #ff516b 100%);
}

.top-mobile-search {
  display: none;
}
.top-mobile-search {
  margin: -16px -15px 19px -18px;
}
.top-mobile-search input {
  border-radius: 0;
  box-shadow: none !important;
}
.top-mobile-search .btn {
  border-radius: 0;
  font-size: inherit;
}
.talent-wrapper-modal {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // margin: 200px 0 0 0;
  padding: 0px !important;
  .react-responsive-modal-closeButton {
    display: none !important;
  }
  .talentheadings-wrappers {
    position: relative;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
    background: linear-gradient(135deg, #ff516b 0%, #826cfd 100%) !important;

    .remove-btns {
      background: none;
      border: none;
      color: #fff;
      outline: none;
      width: 50px;
      i {
        font-size: 18px;
      }
    }
    .talentheading {
      margin: 0;
      padding: 10px;
      color: #fff;
    }
  }

  .talent-popwrap {
    padding: 23px 30px;
    text-transform: capitalize;
    .css-1s2u09g-control {
      border: 1px solid #eceff0;
      border-radius: 23px;
    }
    .btnList {
      display: flex;
      gap: 10px;
      Button {
        margin-top: 0;
        border-radius: 50px !important;
        padding: 3px 30px !important;
        border: none !important;
        box-shadow: none !important;
      }
    }
    .css-1pahdxg-control {
      border: 1px solid #eceff0;
      box-shadow: none;
      border-radius: 23px;
    }
    .select-talent-wrapp {
      width: 100%;
      height: 50px;
      border-radius: 5px;
      padding: 10px;
      outline: none;
      border: 1px solid #eceff0;
      background-color: #f9f9f9;
      margin-bottom: 20px;
    }
    .savebtnwrappp {
      margin-top: 10px;
      text-align: right;
      .talentsevebtn {
        background-color: #0baf4d;
        color: #fff;
        height: 50px;
        border-radius: 5px;
        outline: none;
        font-size: 18px;
        font-weight: bold;
        border: none;
        width: 100%;
        word-break: keep-all;
      }
    }

    .ajexsBox-wrapper {
      width: 100%;
      left: 0;
      top: 52px;
      position: absolute;
      padding: 0;
      z-index: 30;
      max-height: 250px;
      overflow-y: scroll;
      scroll-behavior: smooth;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      .ajesitems {
        display: flex;
        align-items: center;
        background-color: #fff;
        border: 1px solid #eceff0;
        list-style: none;
        padding: 10px 12px;
        cursor: pointer;
        font-size: 13px;
        &:hover {
          background-color: #eceff0;
        }
      }
    }
  }
}

@media (max-width: 575.98px) {
  .top-mobile-search {
    display: block;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .top-mobile-search {
    display: block;
  }
}

.Cat_BottomTab {
  display: flex;
}

.seeMore_container {
  margin-bottom: 4rem;
}
